@import './../../variables.scss';

.ServicePresentationItem {
    text-align: center;

    h5 {
        color: $primary;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .IconContainer {
        align-items: center;
        display: flex;
        margin: 30px 0;
        justify-content: center;
       
        .EntregaIcon,
        .TrayectoriaIcon,
        .StockIcon {
            background-repeat: no-repeat;
            background-size: 140px;
            display: inline-block;
            height: 140px;
            width: 140px;
        }

        .EntregaIcon { background-image: url('./icons/servicio-entrega.png'); }
        .TrayectoriaIcon { background-image: url('./icons/servicio-trayectoria.png'); }
        .StockIcon { background-image: url('./icons/servicio-stock.png'); }
    }
}
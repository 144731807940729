.ProductPresentationItem {
    background-color: white;
    border-bottom-right-radius: 30px;
    height: 265px;
    margin: 15px 0;
    padding-bottom: 10px;

    h6 { 
        font-weight: 600;
        margin: 15px 5px; 
    }
    img {
        height: 195px;
        max-width: 100%;
    }
}
@import './../../variables.scss';
.About {
    p { 
        color: gray;
        font-size: 18px;

        &.MainDescription {
            font-size: 19px;
            font-weight: 600;
        }
    }

    .HighlightSection {
        background-color: $primary;
        background-image: url('./bg.jpg');
        background-repeat: no-repeat;
        background-position: bottom;
        color: white;
        margin-top: 100px;
        padding: 40px;

        .HighlightItem {
            border-left: 5px solid white;
            padding-left: 30px;
            margin: 50px 0;

            h5 {
                color: white;
                font-weight: bold;
            }

            p { color: white; }
        }
    }
}
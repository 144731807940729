.ProductsContainer {
    padding: 20px 0;
    overflow: hidden;

    .FamiliasContainer {
        display: flex;
    
    width: 100%;
    
    justify-content: space-between;
    @media (max-width: 993px) {
        display: block;
    }

        .FamiliaList {
            height: auto;
            overflow: auto;
            width: 100%;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
        }
    }

    .ProductListContainer { 
        
    }

    .ProductFilterContainer { margin-bottom: 20px; }

    @media (max-width: 993px) {
        display: initial;
        .ProductListContainer,
        .FamiliasContainer { 
            float: none;
            margin: 15px 0;
            height: auto;
            width: 100%; 
        }
        .FamiliaList {
            width: 100%;
        }

        .ProductListContainer { 
            padding-left: 0; 
            margin-bottom: 50px;
            button { margin-top: 10px;}
        }
    }
}

.ProductsError { margin-bottom: 30px; }

.UltimaModificacion {
    float: right;
    margin-top: 20px;
}

.xlsIcon {
    width: 60px;
    float: right;
    margin-top: 16px;
    cursor: pointer;
}
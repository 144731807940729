.QtySelector {
    .QtyInput {
        width: 35px;
        text-align: center;
        border: 1px solid black;
        height: 30px;
        font-weight: bold;
    }

    button {
        width: 95px;
    }
    
    .AddBtn { margin-left: 15px; }
    .RemoveBtn {
        background-color: red;
        margin-left: 5px;
    }
}
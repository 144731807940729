@import './../../variables.scss';
.FamiliaDropdown {
    display: flex;
    justify-content: space-around;
    @media (max-width: 993px) {
        display: block;
    }
    div {
        width: 350px;
    }
    h3 { 
        font-size: 16px;

        &.subfamilias-title {
            margin-top: 10px;
        }
    }
}
@import './../../variables.scss';

.CommonNavContainer {
    padding: 15px;
    overflow: hidden;

    .CommonNavLinks {
        li {
            font-weight: 600;
            padding: 20px;

            a.active-route,
            a:hover {
                color: $primary;
                text-decoration: none;
            }
        }
    }
}
@import './../../variables.scss';
.MarcaContainer {
    border-bottom: 1px solid $primary-gray;
    margin-bottom: 20px;
    padding-bottom: 20px;

    a {
        color: black;
        font-size: 13px;
        font-weight: 600;
        display: block;
        margin-top: 22px;
        &:hover {
            color: $primary;
        }
    }
    img {
        float: left;
        margin-right: 10px;
        width: 90px;
    }
}
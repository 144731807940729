@import './../../variables.scss';
.Novedades {
    margin-bottom: 70px;

    button {
        margin-top: 20px;
    }
    ul {
        list-style: disc;
        padding-left: 15px;

        li {
            margin: 10px 0;
        }
        a {
            color: $primary;
        }
    }
}
@import './../../variables.scss';
.ProductList {
    overflow: auto;
    font-size: 14px;
    
    .Porcentages {
        margin: 20px 0;
        display: flex;
        justify-content: space-around;
        @media (max-width: 993px) {
            display: block;
        }
    }
    .PercentajeContainer {
        margin: 10px 0;
        align-items: baseline;
        display: flex;
        @media (max-width: 993px) {
            display: block;
        }
        .title { margin-right: 10px;}
        div {
            padding-left: 0;
            padding-right: 0;
        }
    }
    thead {
        background-color: $primary-dark;
        color: white;
        text-align: center;
        th { padding: 10px; }
    }

    .pagination {
        float: right;
        margin: 30px 0;

        .ActivePage,
        .PageButton {
            border-radius: 0;
            color: $primary;
            background-color: white;
            border-color: $primary;
            margin-right: -1px;

            &:last-child { margin-right: 0; }
        }

        .ActivePage {
            color: white;
            background-color: $primary;
        }
    }
}

.ProductGetStarted {
    margin: 100px auto;
    font-size: 21px;
    text-align: center;
    width: 300px;

    .separator { margin: 20px auto; }
}
